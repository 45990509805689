<template>
	<div class="popup-list"
			v-if="showPopup"
		>
			<div class="popup type02">
				<div class="p-header red">
					<div class="title">결제 수수료 정산 지급 불가</div>
					<div class="btn-close_popup"
						@click="showPopup = false"
					>닫기</div>
				</div>
				<div class="p-body">
					<div class="c01">
						<div class="pc-header"><span class="title">지급불가</span></div>
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title">지급불가일</div>
									<div class="desc">{{ item.dt }}</div>
								</div>
								<div class="item">
									<div class="title">사유</div>
									<div class="desc">{{ item.reason }}</div>
								</div>
							</div>
							<div class="p-text">
								상세 사유가 궁금하신 경우 고객센터에 문의주세요.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>

<script>
	export default{
		name: 'SettlementPopupItem'
		,props: ['item', 'showPopup']
		,data: function(){
			return {
			}
		}
	}
</script>