<template>
	
	<div class="cont" id="p09" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type04" style="margin: 5% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
		
				<div class="p-header">
					<div class="title">{{ summary.businessName }} - {{ program.name }}</div>
					<v-btn @click="close" small color="white" class="float-right" style="position: absolute; right: 10px; top: 15px;">닫기</v-btn>
				</div>
				<div class="p-body">
					<div class="c01">
						<div class="item-list">
							<div class="item item01">
								<div class="img"><img src="@/assets/img/icon-01.png" alt=""></div>
								<div class="content">
									<div class="desc">결제완료</div>
									<div class="amount"><span class="cnt">{{ summary.totalPaymentCount | makeComma }}</span>건</div>
								</div>
							</div>
							<div class="item item02">
								<div class="img"><img src="@/assets/img/icon-02.png" alt=""></div>
								<div class="content">
									<div class="desc">총 결제 금액</div>
									<div class="amount"><span class="cnt">{{ summary.totalPaymentAmount | makeComma }}</span>원</div>
								</div>
							</div>
							<div class="item item03">
								<div class="img"><img src="@/assets/img/icon-03.png" alt=""></div>
								<div class="content">
									<div class="desc">수수료 정산 예정 금액</div>
									<div class="amount"><span class="cnt">{{ summary.totalFeeAmount | makeComma }}</span>원</div>
								</div>
							</div>
							<div class="item item01"  @click="excel">
								<div class="img"><v-icon x-large color="white" style="color: white;">mdi-download-box-outline</v-icon></div>
								<div class="content">
									<div class="desc">결제 상세 내역</div>
									<div class="amount" ><span class="cnt" style="font-size: 1.2em;">
										<vue-excel-xlsx
											:data="excel_items"
											:columns="columns"
											:filename="program.name"
											:sheetname="program.name"
										>
										엑셀 다운로드
										</vue-excel-xlsx>
									</span></div>
								</div>
							</div>
						</div>
					</div>
					<div class="c02">
						<div class="table type08">
							<div class="item-header" style="overflow-y: scroll;">
								<div class="item">
									<div class="td">NO</div>
									<div class="td">서비스 타입</div>
									<div class="td">결제일시</div>
									<div class="td">결제 정보</div>
									<div class="td">결제 금액</div>
									<div class="td">수수료 정산 예정 금액</div>
								</div>
							</div>
							<div class="item-list"	style="height: 450px; overflow-y: scroll">
								<div class="item"
									v-for="(item, index) in items"
									:key="index"
								>
									<div class="td">{{ item.rowNumber }}</div>
									<div class="td">{{ item.serviceTypeName }}</div>
									<div class="td">{{ item.tradDate }}</div>
									<div class="td">{{ item.productName }}</div>
									<div class="td">{{ item.totAmount | makeComma }}</div>
									<div class="td">{{ item.commission | makeComma }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="c03">
						<Pagination
							:options="search"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { columns } from '@/resources/columns/columns'
	
	import Pagination from '@/components/Pagination'

	export default {
		name: 'PaymentsHistory'
		,props: ['user', 'item']
		,components: { Pagination }
		,data: function(){
			return {
				program: {
					name: '가맹점 결제 상세내역'
				}
				,items: [
				]
				,excel_items: [
				]
				,columns: columns.Merchant.payment
				,search_default: {
					expPayDt: this.item.expPayDt
					,pgMerchNo: this.item.pgMerchNo
					,pgTid: this.item.pgTid
				}
				,search: {
				}
				,summary: {
				}
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/merchants/payment'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						this.$set(this.search, 'list_cnt', result.data.content.size)
						
						await this.getSummary()
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,getSummary: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/merchants/payment/summary'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.summary = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,clear: function(){
				this.search = this.search_default
			}
			,close: function(){
				this.$emit('setOverlay', false)
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/merchants/payment/download'
						,data: this.search
						,authorize: true
					})
					console.log(result.data)
					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: async function(){
			this.clear()
			await this.getData()
		}
		,watch: {
			'search.page': {
				handler: function(){
					this.getData()
				}
			}
		}
	}
</script>























